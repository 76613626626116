var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('router-link', {
    staticClass: "btn btn-sm btn-default",
    attrs: {
      "to": {
        name: _vm.ReportInvoiceSinglePath.name
      }
    }
  }, [_c('em', {
    staticClass: "fa fa-backward"
  }), _vm._v(" Manage Invoice Detail ")])], 1), _vm.item ? _c('b-col', [_c('b-form', {
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "card mt-4"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(" Invoice Single Detail (" + _vm._s(this.form.created) + ") ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice ID"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "core_invoice_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.core_invoice_id ? _vm.form.core_invoice_id : "Unknown") + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Period"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "disbursement_period"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.disbursement_period) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Transaction Code"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "transaction_code"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.transaction_code) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Orderline ID"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "orderline_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.orderline_id) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Revenue Formula"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "no-resize": true,
      "rows": "2",
      "value": _vm.form.revenue_formula,
      "disabled": true
    }
  })], 1), _vm.form.item_idax ? _c('b-form-group', {
    attrs: {
      "label": "AX ID"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "item_idax"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.item_idax) + " ")])], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Detail"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "no-resize": true,
      "rows": "18",
      "value": _vm.form.detail,
      "disabled": true
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Created"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "created"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.created) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Modified"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "modified"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.modified) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "no-resize": true,
      "rows": "6",
      "value": _vm.form.description,
      "disabled": true
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Disbursement"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "disbursement"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.disbursement) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Balance"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "balance"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.balance) + " ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Is Paid"
    }
  }, [_c('b-input-group-text', {
    attrs: {
      "id": "is_paid"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.is_paid) + " ")])], 1)], 1)], 1)], 1)])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }