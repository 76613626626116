<template>
	<b-overlay
		:show="isLoading"
		rounded="lg"
		opacity="0.6"
		spinner-variant="primary"
	>
		<template #overlay>
			<div class="d-flex align-items-center">
				<b-spinner small type="grow" variant="secondary"></b-spinner>
				<b-spinner type="grow" variant="dark"></b-spinner>
				<b-spinner small type="grow" variant="secondary"></b-spinner>
			</div>
		</template>
		<b-row class="mt-4">
			<b-col cols="12">
				<router-link class="btn btn-sm btn-default" :to="{name: ReportInvoiceSinglePath.name}">
					<em class="fa fa-backward"></em> Manage Invoice Detail
				</router-link>
			</b-col>
			<b-col v-if="item">
				<b-form @submit="onSubmit">
					<div class="card mt-4">
						<div class="bg-success p-3">
							<h5 class="card-title mb-0 font-weight-bold">
								Invoice Single Detail ({{ this.form.created }})
							</h5>
						</div>
						<div class="card-body">
							<b-row>
								<b-col lg="6" class="mt-4">
									<b-form-group label="Invoice ID">
										<b-input-group-text id="core_invoice_id">
											{{ form.core_invoice_id ? form.core_invoice_id : "Unknown" }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Period">
										<b-input-group-text id="disbursement_period">
											{{ form.disbursement_period }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Transaction Code">
										<b-input-group-text id="transaction_code">
											{{ form.transaction_code }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Orderline ID">
										<b-input-group-text id="orderline_id">
											{{ form.orderline_id }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Revenue Formula">
										<b-form-textarea :no-resize="true" rows="2" :value="form.revenue_formula" :disabled="true"></b-form-textarea>
									</b-form-group>
									<b-form-group label="AX ID" v-if="form.item_idax">
										<b-input-group-text id="item_idax">
											{{ form.item_idax }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Detail">
										<b-form-textarea :no-resize="true" rows="18" :value="form.detail" :disabled="true"></b-form-textarea>
									</b-form-group>
									<b-form-group label="Created">
										<b-input-group-text id="created">
											{{ form.created }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Modified">
										<b-input-group-text id="modified">
											{{ form.modified }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Description">
										<b-form-textarea :no-resize="true" rows="6" :value="form.description" :disabled="true"></b-form-textarea>
									</b-form-group>
									<b-form-group label="Disbursement">
										<b-input-group-text id="disbursement">
											{{ form.disbursement }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Balance">
										<b-input-group-text id="balance">
											{{ form.balance }}
										</b-input-group-text>
									</b-form-group>
									<b-form-group label="Is Paid">
										<b-input-group-text id="is_paid">
											{{ form.is_paid }}
										</b-input-group-text>
									</b-form-group>
								</b-col>
							</b-row>
						</div>
					</div>
				</b-form>
			</b-col>
		</b-row>
	</b-overlay>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ReportInvoiceSinglePath } from "../../router/report";

export default {
	name: "manage-report-invoice-single-detail",
	data() {
		return {
			ReportInvoiceSinglePath,
			debounce: null,
			form: {
				id: null,
				core_invoice_id: null,
				disbursement_period: null,
				transaction_code: null,
				orderline_id: null,
				revenue_formula: null,
				item_idax: null,
				detail: null,
				created: null,
				modified: null,
				description: null,
				disbursement: null,
				balance: null,
				is_paid: null,
			},
		};
	},
	computed: {
		...mapState({
			isError: (state) => state.report.isError,
			isLoading: (state) => state.report.isLoading,
			errorMessage: (state) => state.report.errorMessage,
			successMessage: (state) => state.report.successMessage,
			item: (state) => state.report.item,
		}),
	},
	watch: {
		errorMessage: function() {
			if (!this.errorMessage) return;
			this.messageAlert("error", this.errorMessage, 5000);
		},
		item: function() {
			if (!this.item) {
				this.messageAlert("error", "not found");
				return;
			}
			if (!Object.keys(this.item).length) return;
			this.setDetailReportInvoice();
		},
	},
	created() {
		const invoice_id = this.$route.params.id;
		const invoice_detail_id = this.$route.params.detailId;
		this.fetchReportInvoiceDetailById({
			id: invoice_id,
			detailId: invoice_detail_id,
		});
	},
	methods: {
		...mapActions("report", ["fetchReportInvoiceDetailById"]),
		onSubmit(event) {
			event.preventDefault();
		},
		checkIfVariableIsString(variable) {
			return typeof variable === "string" || variable instanceof String;
		},
		setDetailReportInvoice() {
			const data = this.item;
			this.form.id = data?.id;
			this.form.core_invoice_id = data?.core_invoice_id;
			this.form.disbursement_period = data?.disbursement_period;
			this.form.transaction_code = data?.transaction_code;
			this.form.orderline_id = data?.orderline_id;
			this.form.revenue_formula = data?.revenue_formula;
			this.form.item_idax = data?.item_idax;
			this.form.detail = (this.checkIfVariableIsString(data?.detail)) ? data?.detail : JSON.stringify(data?.detail, null, 2);
			this.form.created = data?.created;
			this.form.modified = data?.modified;
			this.form.description = data?.description;
			this.form.disbursement = this.previewCurrencyIdr(data?.disbursement);
			this.form.balance = this.previewCurrencyIdr(data?.balance);
			this.form.is_paid = data?.invoice_transaction?.is_paid;
		},
		messageAlert(icon, title, timer = 3000) {
			this.$swal({
				toast: "true",
				position: "top-end",
				icon,
				title,
				showConfirmButton: false,
				timer,
				timerProgressBar: true,
			});
		},
		previewCurrencyIdr(value) {
			return new Intl.NumberFormat(
				'id-ID',
				{
					style: 'currency',
					currency: 'IDR'
				}
			).format(value ?? 0);
		},
	},
};
</script>
